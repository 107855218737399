import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, number, object, shape, string } from 'prop-types';
import parseHtml from '../../utils/parseHtml';
import { Icon, Link } from '../../components/elements';
import { Footer, ModelsList, Hero, SEO } from '../../components';
import formatMake from '../../utils/formatMake';
import customBrandConfigs from '../../../config/customBrands';
import regions from '../../../config/regions';

const CompatibilityByModelPage = ({ data, location }) => {
  const {
    seo,
    heading,
    subheading,
    headerImage,
    content,
    theme,
  } = data.allGraphCmsPage.edges[0].node;

  const path = location.pathname.split('/');
  const make = formatMake(path[path.length - 2], '-');
  let region;
  if (path[1] === 'compatibility') {
    region = path[3].toUpperCase();
  } else if (path[1] === 'guides') {
    region = path[2].toUpperCase();
  }
  if (region !== 'CA' && region !== 'EU') {
    region = 'US';
  }

  let models;
  const regionName = regions.find((options) => options.key === region).value;
  const customBrand = customBrandConfigs.find(
    (brand) => brand.region === regionName && brand.manufacturer === make.toLowerCase(),
  );
  if (customBrand) {
    models = customBrand.apps;
  } else {
    const { compatibleVehicles } = data.allCompatibleVehicles;
    models = compatibleVehicles[region].find((brand) => brand.brand === make).models;
  }

  const staticText = {
    modelSelection: {
      heading: `Compatible ${make} models`,
    },
    backToMakesCta: {
      text: 'Back to makes',
    },
  };

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero heading={heading} subheading={subheading} image={headerImage} theme={theme} />
      <div className="bg-white">
        <div className="container py-12 md:pt-16 md:pb-2 space-y-16">
          <h2>{staticText.modelSelection.heading}</h2>
          {models && (
            <ModelsList make={make} models={models} region={region} isCustomBrand={customBrand} />
          )}
          <div>
            <Link
              path={path[1] === 'compatibility' ? '/compatibility/makes/' : '/guides/'}
              classnames="link-cta"
              segmentProperties={{
                section: 'article',
                style: 'button',
                text: staticText.backToMakesCta.text,
                path: '/compatibility/makes/',
              }}
            >
              <Icon name="arrow" classnames="transform rotate-180" />{' '}
              {staticText.backToMakesCta.text}
            </Link>
          </div>
          <div>{parseHtml(content[1].text.html)}</div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CompatibilityByModelPage;

CompatibilityByModelPage.propTypes = {
  data: shape({
    allGraphCmsPage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            path: string.isRequired,
            heading: string.isRequired,
            subheading: string,
            headerImage: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
            seo: shape({
              title: string.isRequired,
              metaDescription: string.isRequired,
            }).isRequired,
            content: arrayOf(
              shape({
                text: shape({
                  html: string.isRequired,
                }).isRequired,
              }).isRequired,
            ),
            theme: string,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    allCompatibleVehicles: shape({
      compatibleVehicles: shape({
        US: arrayOf(
          shape({
            brand: string.isRequired,
            models: arrayOf(
              shape({
                model: string.isRequired,
                years: arrayOf(number).isRequired,
              }).isRequired,
            ).isRequired,
          }),
        ).isRequired,
        EU: arrayOf(
          shape({
            brand: string.isRequired,
            models: arrayOf(
              shape({
                model: string.isRequired,
                years: arrayOf(number).isRequired,
              }).isRequired,
            ).isRequired,
          }),
        ).isRequired,
        CA: arrayOf(
          shape({
            brand: string.isRequired,
            models: arrayOf(
              shape({
                model: string.isRequired,
                years: arrayOf(number).isRequired,
              }).isRequired,
            ).isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export const CompatibilityByModelPageQuery = graphql`
  query CompatibilityByModelPage {
    allGraphCmsPage(filter: { path: { eq: "compatibility/makes" } }) {
      edges {
        node {
          heading
          subheading
          path
          headerImage {
            gatsbyImageData(placeholder: NONE)
          }
          seo {
            title
            metaDescription
          }
          theme
          content {
            ... on GraphCMS_TextBlock {
              remoteTypeName
              text {
                html
              }
            }
          }
        }
      }
    }
    allCompatibleVehicles {
      compatibleVehicles {
        CA {
          brand
          models {
            model
            years
          }
        }
        EU {
          brand
          models {
            years
            model
          }
        }
        US {
          brand
          models {
            model
            years
          }
        }
      }
    }
  }
`;
